<template>
  <v-card>
    <v-card-title>
    Choose a mailing list for this email
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="mailingList"
        :items="mailingLists"
        :item-text="getMailingListText"
        return-object
        outlined
        dense
        block
        hide-details
        label="Mailing List"
      >
      </v-select>
      <v-form ref="emailForm" v-model="emailFormValid" @submit.prevent>
        <v-text-field
          class="mt-4"
          v-model="email.description"
          label="Description"
          required
          dense
          outlined
          :rules="[v => !!v || 'Item is required']"
        ></v-text-field>
        <v-text-field
          v-model="email.subject"
          label="Subject for email"
          required
          dense
          outlined
          :rules="[v => !!v || 'Item is required']"
        ></v-text-field>
        <label>HTML</label>
        <!-- <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor> -->
        <vue-editor class="mb-8" :editorOptions="editorSettings" v-model="email.body"></vue-editor>
        <!-- <vue-ckeditor
           v-model="email.body"
          :config="config"
          required
          @blur="onBlur($event)"
          @focus="onFocus($event)"
          @contentDom="onContentDom($event)"
          @dialogDefinition="onDialogDefinition($event)"
          @fileUploadRequest="onFileUploadRequest($event)"
          @fileUploadResponse="onFileUploadResponse($event)" /> -->
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn :disabled="!canSend" color="success" class="mr-4" @click.native="sendEmail()">
        Send
      </v-btn>
      <v-btn :disabled="!canTest" color="success" class="mr-4" @click.native="saveEmail()">
        Save
      </v-btn>
      <v-btn :disabled="!canTest" color="success" class="mr-4" @click.native="sendEmail()">
        Test Email
      </v-btn>
    </v-card-actions>
  <confirm-dialog ref="confirmDialog" />
  </v-card>
</template>

<script>
import MailingListApi from '@/api/mailingList'
import EmailApi from '@/api/email'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import MailingList from '@/constants/mailingLists'
import { VueEditor, Quill } from "vue2-editor";
import ImageResize from "quill-image-resize-module";
import PromotionApi from "@/api/promotion"
Quill.register("modules/imageResize", ImageResize);

export default {
  components: {
    ConfirmDialog,
    VueEditor
  },
  data() {
    return {
      editorSettings: {
        modules: {
          imageResize: {}
        }      },
      template: '',
      emailFormValid: false,
      mailingList: null,
      mailingLists: [],
      email: {
        id: null,
        body: null,
        subject: null
      },
      config: {
        toolbar: [
          { name: 'document', items: [ 'Source' ] },
          { name: 'styles', items: [ 'Format'] },
          { name: 'clipboard', items: [ 'Paste', 'PasteText', 'PasteFromWord', 'Undo', 'Redo' ] },
          { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'RemoveFormat' ] },
          { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', ] },
          { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
          { name: 'links', items: [ 'Link'] },
          { name: 'insert', items: [ 'Table', 'HorizontalRule' ] },
          ],
        height: 300,
      }
    }
  },
  async created () {
    console.log(MailingList)
    MailingListApi.loadMailingLists().then( resp =>{
      const _mailingLists = Object.keys(resp.data)
      this.mailingLists = _mailingLists.map( key => ({key: key, name: MailingList[key].name, contacts: resp.data[key]}))
    })
  },
  mounted: function () {},
  computed:{
    canSend: function() {
      return !!this.emailFormValid && !!this.mailingList && !!this.email.body },
    canTest: function() {
      return !!this.emailFormValid && !!this.email.body }
  },
  watch: {},
  methods: {
    async sendEmail() {
      let list = ''
      // this.mailingList.contacts.map(item => {
      //   list = list + item.name + '<br>'
      // })
      if ( true
        // await this.$refs.confirmDialog.open(
        //   'Are you sure about this email?',
        //   `<p>The email will be sent to these ${this.mailingList.contacts.length} contacts from list
        //    '<b>${this.mailingList.name}</b>'</p>
        //   ${list}`
        // )
      ) {
      const testContact = {
        "emailAddress": "r.tugwell@forthmedia.com",
        "firstName": "Richard",
        "name": "Forthmedia",
        "contactType": "NEITHER",
        "id": null
      }
      PromotionApi.emailPromotion({ promotion: this.email.body, template: 'standardEmail', mailingList: [] }).then((result) => {
        console.log(result.data)
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    }
    },
    saveEmail() {
      EmailApi.saveEmail(this.email).then((result) => {
        console.log(result)
      }).catch(
        err => {
          console.log('err', err)
        }
      )
    },
    getMailingListText: (item) => `${item.name} (${item.contacts.length})`,
    onBlur(evt) {
      console.log(this.content,evt);
    },
    onFocus(evt) {
      console.log(this.content,evt);
    },
    onContentDom(evt) {
      console.log(this.content,evt);
    },
    onDialogDefinition(evt) {
      console.log(evt);
    },
    onFileUploadRequest(evt) {
      console.log(evt);
    },
    onFileUploadResponse(evt) {
      console.log(evt);
    }  }
}
</script>


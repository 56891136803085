import axios from 'axios'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/marketing/email`

const loadEmails = () => {
  return axios.get(api, {})
}
const loadEmail = (id) => {
  return axios.get(api + '/' + id)
}
const saveEmail = (email) => {
  return axios.put(api, { ...email })
}
const sendEmail = (email) => {
  return axios.post(api + '/send', { ...email })
}

export default {
  loadEmails,
  loadEmail,
  saveEmail,
  sendEmail
}
